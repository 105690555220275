import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
	heroImgBg: {
		position: 'absolute',
		right: '-10%',
		top: '-5%',
		zIndex: 9999,
		maxWidth: '600px',
		[theme.breakpoints.down('md')]: {
			right: '-17%',
			bottom: '-60%',
			maxWidth: '550px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},

	heroImgBgProductivity: {
		position: 'absolute',
		right: '-3%',
		top: '-5%',
		zIndex: 9999,
		maxWidth: '520px',

		[theme.breakpoints.down('md')]: {
			top: '-18%',
			right: '-5%',
			maxWidth: '430px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},
	heroImgBgGrowth: {
		position: 'absolute',
		right: '-3%',
		top: '-5%',
		zIndex: 9999,
		maxWidth: '520px',

		[theme.breakpoints.down('md')]: {
			top: '-18%',
			right: '-5%',
			maxWidth: '430px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},

	heroImgBgScheduling: {
		position: 'absolute',
		right: '-11%',
		top: '-20%',
		zIndex: 9999,
		maxWidth: '630px',
		[theme.breakpoints.down('md')]: {
			right: '-20%',
			maxWidth: '550px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},

	heroImgBgCRM: {
		position: 'absolute',
		right: '-13%',
		top: '-20%',
		zIndex: 9999,
		maxWidth: '650px',
		[theme.breakpoints.down('md')]: {
			top: '-15%',
			right: '-25%',
			maxWidth: '630px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},
	heroImgBgMarketing: {
		position: 'absolute',
		right: '-11%',
		top: '-20%',
		zIndex: 9999,
		maxWidth: '630px',
		[theme.breakpoints.down('md')]: {
			right: '-20%',
			bottom: '-60%',
			maxWidth: '550px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},
	heroImgBgSupport: {
		position: 'absolute',
		right: '-3%',
		top: '-20%',
		zIndex: 9999,
		maxWidth: '520px',

		[theme.breakpoints.down('md')]: {
			top: '-23%',
			right: '-5%',
			maxWidth: '430px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			top: '50px',
			width: '608px',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			top: '20px',
			maxWidth: '100%',
		},
	},
}));

export const ImgRight = ({ heroImage, id }) => {
	const classes = useStyles();
	const md = useMediaQuery('(max-width: 960px)');
	return (
		<Grid
			container
			item
			direction='column'
			// justifyContent='flex-start'
			alignItems={md ? 'center' : 'flex-start'}
			xs={12}
			md={5}>
			<GatsbyImage
				image={heroImage}
				alt='hero image collage'
				className={
					id === 'b720ff6c-323c-45e9-9d28-76e9904a29f5'
						? classes.heroImgBgSupport
						: // : id === "" ? classes.heroImgBgOverview
						id === '7426b498-801d-4c25-ab59-6aa0775806ba'
						? classes.heroImgBgProductivity
						: id === '78a3d60a-aacc-44a3-b76e-064cfd0ed9ee'
						? classes.heroImgBgGrowth
						: id === 'c4f9a546-7741-482d-b5e5-1aa284a8b25d'
						? classes.heroImgBgScheduling
						: id === 'f9a07114-dbba-451b-ac70-9776ce844729'
						? classes.heroImgBgCRM
						: id === '6ff01c2f-1718-47e1-8c9a-4926be069079'
						? classes.heroImgBgMarketing
						: classes.heroImgBg
				}
			/>
		</Grid>
	);
};
