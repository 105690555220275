import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
import { BgImage } from 'gbimage-bridge';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Container, useMediaQuery } from '@material-ui/core';

// import { EmailInput } from '../../Home/EmailInput';
import { WaveUpHero } from '../WaveSVGs/WaveUpHero';
import { HeroBody } from '../CustomStyles/Typography';
import { ImgRight } from './ImgRight';
import { HeroCtaButton } from './HeroCtaButton';

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.white,
		margin: '0',
		[theme.breakpoints.down('md')]: {
			margin: '0px',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
			width: '100%',
		},
	},
	header: {
		lineHeight: 1.1,
		fontSize: '3.15rem',
		margin: '0 0 1rem 0',
		[theme.breakpoints.down('md')]: {
			fontSize: '2.4rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
			// marginTop: '20px',
		},
	},
	subHeader: {
		fontSize: '1.5rem',
		fontWeight: 400,
		lineHeight: 1.3,
		textTransform: 'uppercase',
		fontFamily: ['Roboto', 'sans-serif'].join(),
		margin: '0 0 1rem 0',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.3rem',
		},
	},
	imgCont: {
		padding: '6rem 0 4rem 0',
		maxWidth: '100vw',
		height: '100%',
		overflow: 'visible',
		backgroundSize: 'cover',
		background:
			'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',
		[theme.breakpoints.down('md')]: {},
		[theme.breakpoints.down('sm')]: {
			paddingTop: '3rem',
			backgroundPosition: 'right',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '3rem 0',
		},
	},
	heroImgBg: {
		position: 'absolute',
		right: '-10%',
		bottom: '-80%',
		zIndex: 9999,
		[theme.breakpoints.down('md')]: {
			right: '-15%',
			bottom: '-60%',
			maxWidth: '530px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			bottom: '-50px',
			maxWidth: '608px',
		},
		[theme.breakpoints.down('xs')]: {
			bottom: '0',
			maxWidth: '100%',
			//marginTop: '50px',
		},
	},
	heroImgBgSupport: {
		position: 'absolute',
		right: '-93px',
		bottom: '-233px',
		zIndex: 9999,
		[theme.breakpoints.down('md')]: {
			right: '-85px',
			bottom: '-211px',
			maxWidth: '530px',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			right: 0,
			bottom: '-83px',
			maxWidth: '608px',
		},
		[theme.breakpoints.down('xs')]: {
			bottom: '0',
			maxWidth: '100%',
			marginTop: '50px',
		},
	},
	// imgRight: {
	// 	marginLeft: '-10rem',
	// 	[theme.breakpoints.down('lg')]: {
	// 		marginLeft: '-9rem',
	// 		height: '560px',
	// 	},
	// 	// [theme.breakpoints.down('md')]: {
	// 	// 	marginLeft: '-4rem',
	// 	// 	height: '380px',
	// 	// },
	// 	[theme.breakpoints.down('sm')]: {
	// 		marginLeft: '-8rem',
	// 		height: '300px',
	// 	},
	// 	[theme.breakpoints.down('xs')]: {
	// 		marginLeft: '0',
	// 		height: '260px',
	// 	},
	// },
}));

export const HeroImageRight = ({ _id, hero, heroImage, feature }) => {
	const classes = useStyles();
	const md = useMediaQuery('(max-width: 960px)');
	const xs = useMediaQuery('(max-width: 481px)');

	const theme = useTheme();

	//if hero has subheader then remove margin-top from h1
	const isFeature =
		feature && hero._rawContent[0].style === 'h4' ? true : false;

	return (
		<>
			<BgImage
				className={classes.imgCont}
				image={hero?.backgroundImage?.asset?.gatsbyImageData}
				style={{
					lineHeight: '0',
					overflowX: 'visible',
				}}>
				<Container
					fixed
					style={{ position: 'relative', maxHeight: md ? '875px' : 'none' }}>
					<Grid container direction='row' justifyContent='flex-start'>
						<Grid
							container
							item
							direction='column'
							justifyContent='flex-start'
							alignItems='flex-start'
							xs={12}
							sm={10}
							md={heroImage ? 6 : isFeature ? 8 : 10}>
							{hero?._rawContent.map((content, index) => (
								<PortableText
									key={index}
									content={content}
									className={classes.text}
									serializers={{
										h1: ({ children }) => (
											<Typography
												variant='h1'
												className={classes.header}
												style={{ marginTop: isFeature && 0 }}>
												{children}
											</Typography>
										),
										h2: ({ children }) => (
											<Typography variant='h2' className={classes.header}>
												{children}
											</Typography>
										),
										h4: ({ children }) => (
											<Typography
												variant='h4'
												className={
													isFeature ? classes.subHeader : classes.header
												}>
												{children}
											</Typography>
										),
										normal: ({ children }) => <HeroBody>{children}</HeroBody>,
									}}
								/>
							))}{' '}
							{hero.ctaButtonText && (
								// <Grid container direction='row'>
								<Grid item>
									<HeroCtaButton hero={hero} />
								</Grid>
								// </Grid>
							)}
						</Grid>

						{!!heroImage ? <ImgRight heroImage={heroImage} id={_id} /> : null}
					</Grid>
				</Container>
				<WaveUpHero height='213' width='100%' fill='white' />
			</BgImage>
		</>
	);
};
